import React from 'react';
import cn from 'classnames';

import styles from './InputMessage.module.scss';

export interface InputMessageProps {
  dataCy?: string;
  className?: string;
}

export const InputMessage: React.FC<InputMessageProps> = ({
  dataCy,
  children,
  className,
}) => (
  <p
    data-cy={dataCy}
    aria-live="assertive"
    aria-relevant="all"
    className={cn(styles.inputMessage, className)}
  >
    {children}
  </p>
);
