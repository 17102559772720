import React from 'react';
import cn from 'classnames';

import styles from './Container.module.scss';

export interface ContainerProps {
  className?: string;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return <div className={cn(styles.wrapper, className)}>{children}</div>;
};
