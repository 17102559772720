import React from 'react';
import { useNavigate } from 'react-router';

import { Button } from '@components/button/Button';
import { authService } from '@services/auth-service/auth-service';
import { routes } from '@shared/constants/routes';
import { Container } from '@components/container/Container';
import { useGlobalContext } from '@store/global-context/use-global-context';
import { ErrorMessage } from '@components/forms/inputs/error-message/ErrorMessage';

import styles from './Dashboard.view.module.scss';
import { Heading } from '@components/heading/Heading';

export const DashboardView = () => {
  const {
    user: { email, userName, hasEmailVerified },
  } = useGlobalContext();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await authService.logOut();

    navigate(routes.logIn);
  };

  return (
    <div className={styles.wrapper}>
      <Container>
        <Heading level="h1" className={styles.heading}>
          Dashboard
        </Heading>
        <ul>
          <li>
            <strong>Username</strong>: {userName}
          </li>
          <li>
            <strong>Email</strong>: {email}{' '}
            {hasEmailVerified ? (
              <small style={{ color: 'green' }}>(verified)</small>
            ) : (
              <small style={{ color: 'red' }}>(Not verified)</small>
            )}
          </li>
          <li>
            <strong>Points</strong>: 0
          </li>
        </ul>
        {!hasEmailVerified && (
          <>
            <ErrorMessage>
              Please verify your email. We've sent a magic link to your mailbox
              at: <strong>{email}</strong>
            </ErrorMessage>
            <br />
          </>
        )}

        <Button type="button" onClick={handleLogout}>
          Log out
        </Button>
      </Container>
    </div>
  );
};
