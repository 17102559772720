import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  setPersistence,
  signOut,
  updateProfile,
  Auth,
  User,
  UserCredential,
} from 'firebase/auth';

import firebaseClient from './firebase-client';
import { t10s } from './auth-service.t10s';

export interface IAuthService {
  createAccount(
    email: string,
    password: string,
    username: string
  ): Promise<void>;
  logIn(email: string, password: string): Promise<UserCredential>;
  logOut(): Promise<void>;
  sendVerificationEmail(continueUrl: string): Promise<void>;
  getIsAuthenticated(): Promise<boolean>;
  getAuth(): Auth;
}

export class AuthService implements IAuthService {
  private readonly auth: Auth;
  private user: User | null = null;

  constructor(auth: Auth) {
    this.auth = auth;
    setPersistence(auth, browserLocalPersistence);

    auth.onAuthStateChanged((user) => {
      this.user = user;
    });
  }

  public async createAccount(
    email: string,
    password: string,
    username: string
  ) {
    try {
      const { user } = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );

      await updateProfile(user, { displayName: username });
    } catch (e) {
      throw e;
    }
  }

  public logIn(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public logOut() {
    return signOut(this.auth);
  }

  public sendVerificationEmail(continueUrl: string) {
    if (!this.user) throw new Error(t10s.errors.userNotFound);

    return sendEmailVerification(this.user, {
      url: continueUrl,
    });
  }

  public getIsAuthenticated() {
    return new Promise<boolean>((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(!!user);
      }, reject);
    });
  }

  public getAuth() {
    return this.auth;
  }
}

export const authService = new AuthService(getAuth(firebaseClient));
