import { createContext } from 'react';

export type DefaultValues = {
  user: {
    isLoading: boolean;
    hasEmailVerified?: boolean;
    isAuthenticated: boolean;
    email: string | null;
    userName: string | null;
  };
};

export const defaultValues: DefaultValues = {
  user: {
    isLoading: true,
    hasEmailVerified: false,
    isAuthenticated: false,
    email: null,
    userName: null,
  },
};

export const GlobalContext = createContext(defaultValues);
