import React from 'react';
import cn from 'classnames';

import styles from './Label.module.scss';

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  text?: string;
}

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ text, children, className, ...props }, ref) => {
    return (
      <label className={cn(className)} {...{ ref, ...props }}>
        {text && <span className={styles.text}>{text}</span>}
        {children}
      </label>
    );
  }
);
