import React, { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { Container } from '@components/container/Container';
import { Input } from '@components/forms/inputs/input/Input';
import { EmailInput } from '@components/forms/inputs/email-input/EmailInput';
import { PasswordInput } from '@components/forms/inputs/password-input/PasswordInput';
import { Form } from '@components/forms/form/Form';
import { Button } from '@components/button/Button';
import { Heading } from '@components/heading/Heading';
import { Link } from '@components/link/Link';
import { routes } from '@shared/constants/routes';
import { authService } from '@services/auth-service/auth-service';
import { verifyEmailLink } from '@shared/constants/links';
import { useFormMethods } from '@components/forms/form/hooks/use-form-methods';
import { useGlobalContext } from '@store/global-context/use-global-context';

import styles from './SignUp.view.module.scss';

export const SignUpView = () => {
  const {
    user: { isAuthenticated },
  } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useFormMethods({});
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await authService.createAccount(data.email, data.password, data.username);
      await authService.sendVerificationEmail(verifyEmailLink);

      navigate(routes.dashboard);
    } catch (e: any) {
      setIsLoading(false);
      switch (e.code) {
        case 'auth/weak-password':
          formMethods.setError('password', {
            message: 'Password is too weak (6+ characters)',
          });
          break;
        case 'auth/email-already-in-use':
          formMethods.setError('email', {
            message: 'This email is already used',
          });
          break;
        default:
          throw e;
      }
    }
  }, []);

  if (isAuthenticated) return <Navigate to={routes.dashboard} />;

  return (
    <Form {...{ formMethods }} onSubmit={handleSubmit} className={styles.form}>
      <Container className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <Heading level="h2" className={styles.logo}>
            Heep
          </Heading>
        </div>

        <div>
          <Heading level="h1" className={styles.title}>
            Create account
          </Heading>
          <Input
            name="username"
            placeholder="MrHeep"
            label="Username"
            isRequired
          />
          <EmailInput name="email" isRequired />
          <PasswordInput name="password" isRequired />
        </div>

        <div className={styles.actionsWrapper}>
          <Button type="submit" {...{ isLoading }}>
            Submit
          </Button>
          <Link to={routes.logIn} className={styles.link}>
            Already have an account? <strong>Log in!</strong>
          </Link>
        </div>
      </Container>
    </Form>
  );
};
