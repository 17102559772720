import React, { useCallback } from 'react';
import { FormProvider, Mode, Resolver } from 'react-hook-form';
import { useFormMethods } from './hooks/use-form-methods';

export interface FormProps {
  className?: string;
  dataCy?: string;
  defaultValues?: object;
  mode?: Mode;
  resolver?: Resolver;
  onSubmit: (data: any, e?: React.BaseSyntheticEvent) => void;
  formMethods?: ReturnType<typeof useFormMethods>;
}

export const Form: React.FC<FormProps> = ({
  onSubmit,
  dataCy,
  className,
  children,
  defaultValues,
  mode,
  resolver,
  formMethods,
}) => {
  const defaultFormMethods = useFormMethods({
    defaultValues,
    mode,
    resolver,
    formMethods,
  });

  const stopPropagationAndHandleSubmit = useCallback(
    (e) => {
      e.stopPropagation();
      return defaultFormMethods.handleSubmit(onSubmit)(e);
    },
    [defaultFormMethods, onSubmit]
  );

  return (
    <FormProvider {...defaultFormMethods}>
      <form
        onSubmit={stopPropagationAndHandleSubmit}
        data-cy={dataCy}
        noValidate
        {...{ className }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
