import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import cn from 'classnames';

import styles from './Link.module.scss';

export interface LinkProps extends RouterLinkProps {
  className?: string;
}

export const Link: React.FC<LinkProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <RouterLink {...props} className={cn(styles.wrapper, className)}>
      {children}
    </RouterLink>
  );
};
