import React from 'react';

import {
  InputMessage,
  InputMessageProps,
} from '@components/forms/inputs/input-message/InputMessage';

import styles from './ErrorMessage.module.scss';

export const ErrorMessage: React.FC<InputMessageProps> = ({
  dataCy,
  children,
}) => (
  <InputMessage className={styles.errorMessage} {...{ dataCy }}>
    {children}
  </InputMessage>
);
