import { InputProps } from '@components/forms/inputs/input/Input';

export const _t10s = {
  errors: {
    generic: 'Oops... something went wrong',
    emailInUse: {
      title: 'Seems like this email is already in use',
      description: 'Try using another email',
    },
    phoneNumberInUse: {
      title: 'This phone number is already in use',
      cta: 'Change phone number',
    },
    invalidOtpCode:
      "Uh oh, confirmation code doesn't match. Try entering it again or requesting a new one.",
    inputsValidation: {
      required: 'This field is required',
      min: (value: InputProps['min']) =>
        `The value can not be smaller than ${value}`,
      max: (value: InputProps['max']) =>
        `The value can not be greater than ${value}`,
      minLength: (
        length: InputProps['minLength'],
        fieldName: string | undefined = 'The input'
      ) => `${fieldName} can not be shorter than ${length} characters`,
      maxLength: (
        length: InputProps['maxLength'],
        fieldName: string | undefined = 'The input'
      ) => `${fieldName} can not be longer than ${length} characters`,
      email: {
        invalidFormat: 'That doesn’t look right. Please provide a valid email',
      },
    },
  },
};
