import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { routes } from '@shared/constants/routes';
import { SignUpView } from '@features/sign-up/SignUp.view';
import { LogInView } from '@features/log-in/LogIn.view';
import { PrivateRoute } from '@components/private-route/PrivateRoute';
import { DashboardView } from '@features/dashboard/Dashboard.view';
import { EmptyView } from '@features/empty-view/Empty.view';
import { Navigation } from '@components/navigation/Navigation';
import { useGlobalContext } from '@store/global-context/use-global-context';

import styles from './App.module.scss';

const App = () => {
  const {
    user: { isAuthenticated },
  } = useGlobalContext();

  return (
    <div className={styles.wrapper}>
      <Routes>
        <Route path={routes.home} element={<Navigate to={routes.logIn} />} />
        <Route path={routes.signUp} element={<SignUpView />} />
        <Route path={routes.logIn} element={<LogInView />} />
        <Route path={routes.forgotPassword} element={<EmptyView />} />
        <Route path={routes.dashboard} element={<PrivateRoute />}>
          <Route path={routes.dashboard} element={<DashboardView />} />
        </Route>
      </Routes>
      {isAuthenticated && <Navigation />}
    </div>
  );
};

export default App;
