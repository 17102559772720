import { ChangeEventHandler, FocusEventHandler, useCallback } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export const useEnhancedHandlers = <T>({
  inputMethods,
  onChange,
  onBlur,
}: {
  inputMethods: UseFormRegisterReturn;
  onChange?: ChangeEventHandler<T>;
  onBlur?: FocusEventHandler<T>;
}) => {
  const handleOnChange = useCallback(
    (e) => {
      inputMethods.onChange(e);
      onChange?.(e);
    },
    [inputMethods, onChange]
  );

  const handleOnBlur = useCallback(
    (e) => {
      inputMethods.onBlur(e);
      onBlur?.(e);
    },
    [inputMethods, onBlur]
  );

  return {
    ...inputMethods,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  };
};
