export const routes = {
  home: '/',
  logIn: '/log-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  dashboard: '/dashboard',
  addNewEntry: '/add-new-entry',
  statistics: '/statistics',
  groups: '/groups',
  notifications: '/notifications',
};
