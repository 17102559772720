import { t10s } from './PasswordInput.t10s';
import { Input, InputProps } from '@components/forms/inputs/input/Input';

export interface PasswordInputProps extends Omit<InputProps, 'name'> {
  name: string;
}

export const PasswordInput = ({ ...props }: PasswordInputProps) => (
  <Input
    label={t10s.label}
    placeholder="6+ characters"
    type="password"
    {...props}
  />
);
