import React from 'react';
import { Navigate, Outlet } from 'react-router';

import { routes } from '@shared/constants/routes';
import { useGlobalContext } from '@store/global-context/use-global-context';

export const PrivateRoute = () => {
  const {
    user: { isLoading, isAuthenticated },
  } = useGlobalContext();

  if (!isLoading) {
    return isAuthenticated ? <Outlet /> : <Navigate to={routes.logIn} />;
  }

  return <></>;
};
