import React, { useEffect, useState } from 'react';
import { authService } from '@services/auth-service/auth-service';

import { DefaultValues, defaultValues, GlobalContext } from './global-context';

export const GlobalContextProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<DefaultValues>(defaultValues);

  useEffect(() => {
    authService.getAuth().onAuthStateChanged((user) => {
      setValue((prevState) => ({
        ...prevState,
        user: {
          isAuthenticated: !!user,
          hasEmailVerified: user?.emailVerified,
          isLoading: false,
          email: user?.email || null,
          userName: user?.displayName || null,
        },
      }));
    });
  }, []);

  return (
    <GlobalContext.Provider {...{ value }}>{children}</GlobalContext.Provider>
  );
};
