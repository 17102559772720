import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

import { BUTTON_VARIANT } from './constants';
import styles from './Button.module.scss';

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled' | 'type'> {
  isLoading?: boolean;
  variant?: BUTTON_VARIANT;
  isDisabled?: boolean;
  type: 'submit' | 'reset' | 'button';
}

export const Button: React.FC<ButtonProps> = ({
  isLoading = false,
  variant = BUTTON_VARIANT.PRIMARY,
  children,
  isDisabled,
  ...props
}) => (
  <button
    className={cn(styles.wrapper, styles[variant])}
    {...props}
    disabled={isDisabled || isLoading}
  >
    {isLoading ? 'Loading...' : children}
  </button>
);
