import { Container } from '@components/container/Container';
import { Heading } from '@components/heading/Heading';
import { Link } from '@components/link/Link';
import { routes } from '@shared/constants/routes';
import { Button } from '@components/button/Button';

const linkCopy = '< Go Back';
export const EmptyView = () => (
  <Container>
    <Heading level="h2">Nothing to see here yet</Heading>
    <Link to={routes.home}>
      <Button type="button">{linkCopy}</Button>
    </Link>
  </Container>
);
