import React from 'react';
import cn from 'classnames';

import { Level } from './types';

import styles from './Heading.module.scss';

export interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
  level: Level;
  visualLevel?: Level;
}

export const Heading = ({
  children,
  className,
  level,
  visualLevel,
  ...rest
}: Props) => {
  const Tag = level as React.ElementType<JSX.IntrinsicElements[Level]>;

  return (
    <Tag className={cn(styles[visualLevel || level], className)} {...rest}>
      {children}
    </Tag>
  );
};
