import { _t10s } from '@shared/constants/translations';
import { Input, InputProps } from '@components/forms/inputs/input/Input';

import { t10s } from './EmailInput.t10s';
import { EMAIL_REGEX } from './constants';

export type EmailInputProps = Omit<InputProps, 'pattern'>;

export const EmailInput = ({ ...props }: EmailInputProps) => (
  <Input
    label={t10s.label}
    type="email"
    placeholder="example@domain.com"
    pattern={{
      value: EMAIL_REGEX,
      message: _t10s.errors.inputsValidation.email.invalidFormat,
    }}
    {...props}
  />
);
