import {
  useForm,
  Resolver,
  Mode,
  UseFormReturn,
  FieldValues,
} from 'react-hook-form';

export interface UseFormMethodsArgs {
  defaultValues?: object;
  mode?: Mode;
  resolver?: Resolver;
  formMethods?: UseFormReturn<FieldValues, object>;
}

export const useFormMethods = ({
  defaultValues,
  mode,
  resolver,
  formMethods,
}: UseFormMethodsArgs) => {
  const defaultFormMethods = useForm({
    defaultValues,
    mode,
    resolver,
  });

  if (formMethods) return { ...formMethods, defaultValues };

  return {
    ...defaultFormMethods,
    defaultValues,
  };
};
