import { ReactElement, InputHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

import { ErrorMessage } from '@components/forms/inputs/error-message/ErrorMessage';
import { Label } from '@components/forms/inputs/label/Label';

import styles from './RawInput.module.scss';

export interface RawInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled' | 'required'> {
  label?: string;
  error?: string | ReactElement;
  dataCy?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  noMargin?: boolean;
}

export const RawInput = forwardRef<HTMLInputElement, RawInputProps>(
  (
    {
      label,
      noMargin,
      className,
      error,
      dataCy,
      isDisabled,
      isRequired,
      ...inputProps
    },
    ref
  ) => {
    const hasError = typeof error !== 'undefined';

    return (
      <div className={cn({ [styles.withMargin]: !noMargin }, className)}>
        <Label htmlFor={inputProps.name} text={label} className={styles.label}>
          <input
            aria-invalid={hasError}
            className={cn(styles.input, { [styles.isInvalid]: hasError })}
            data-cy={dataCy}
            disabled={isDisabled}
            required={isRequired}
            {...{ ref, ...inputProps }}
          />
        </Label>
        {error && (
          <ErrorMessage dataCy={dataCy && `${dataCy}-error`}>
            {error}
          </ErrorMessage>
        )}
      </div>
    );
  }
);
