import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';

import { routes } from '@shared/constants/routes';

import { ReactComponent as HomeIcon } from './assets/icon-house.svg';
import { ReactComponent as StatsIcon } from './assets/icon-bar-graph.svg';
import { ReactComponent as AddEntryIcon } from './assets/icon-add-new.svg';
import { ReactComponent as GroupsIcon } from './assets/icon-users.svg';
import { ReactComponent as NotificationsIcon } from './assets/icon-bell.svg';
import styles from './Navigation.module.scss';

export const Navigation = () => {
  return (
    <nav className={styles.wrapper}>
      <ul className={styles.linksList}>
        <li className={styles.linkWrapper}>
          <NavLink
            to={routes.dashboard}
            className={({ isActive }) =>
              cn(styles.link, { [styles.isActive]: isActive })
            }
          >
            <HomeIcon />
            <span className={styles.linkTitle}>Home</span>
          </NavLink>
        </li>
        <li className={styles.linkWrapper}>
          <NavLink
            to={routes.statistics}
            className={({ isActive }) =>
              cn(styles.link, { [styles.isActive]: isActive })
            }
          >
            <StatsIcon />
            <span className={styles.linkTitle}>Statistics</span>
          </NavLink>
        </li>
        <li className={styles.linkWrapper}>
          <NavLink
            to={routes.addNewEntry}
            className={cn(styles.link, styles.addNewEntry)}
          >
            <AddEntryIcon />
          </NavLink>
        </li>
        <li className={styles.linkWrapper}>
          <NavLink
            to={routes.groups}
            className={({ isActive }) =>
              cn(styles.link, { [styles.isActive]: isActive })
            }
          >
            <GroupsIcon />
            <span className={styles.linkTitle}>Groups</span>
          </NavLink>
        </li>
        <li className={styles.linkWrapper}>
          <NavLink
            to={routes.notifications}
            className={({ isActive }) =>
              cn(styles.link, { [styles.isActive]: isActive })
            }
          >
            <NotificationsIcon />
            <span className={styles.linkTitle}>Notifications</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
