import React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';

import { _t10s } from '@shared/constants/translations';
import { useEnhancedHandlers } from '@components/forms/hooks';

import { RawInput, RawInputProps } from './RawInput';

export interface InputProps
  extends Omit<RawInputProps, 'name' | 'pattern' | 'error'> {
  name: string;
  pattern?: RegisterOptions['pattern'];
  validate?: RegisterOptions['validate'];
}

export const Input = ({
  name,
  onChange,
  onBlur,
  isRequired = false,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  validate,
  ...props
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name]?.message;

  const inputMethods = register(name, {
    required: isRequired && _t10s.errors.inputsValidation.required,
    maxLength: maxLength && {
      value: maxLength,
      message: _t10s.errors.inputsValidation.maxLength(maxLength),
    },
    minLength: minLength && {
      value: minLength,
      message: _t10s.errors.inputsValidation.minLength(minLength),
    },
    max: max && {
      value: max,
      message: _t10s.errors.inputsValidation.max(max),
    },
    min: min && {
      value: min,
      message: _t10s.errors.inputsValidation.min(min),
    },
    pattern,
    validate,
  });

  const enhancedInputMethods = useEnhancedHandlers<HTMLInputElement>({
    inputMethods,
    onBlur,
    onChange,
  });

  return <RawInput {...{ ...props, ...enhancedInputMethods, error }} />;
};
