import React, { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { Container } from '@components/container/Container';
import { EmailInput } from '@components/forms/inputs/email-input/EmailInput';
import { PasswordInput } from '@components/forms/inputs/password-input/PasswordInput';
import { Form } from '@components/forms/form/Form';
import { Button } from '@components/button/Button';
import { Heading } from '@components/heading/Heading';
import { Link } from '@components/link/Link';
import { routes } from '@shared/constants/routes';
import { authService } from '@services/auth-service/auth-service';
import { ErrorMessage } from '@components/forms/inputs/error-message/ErrorMessage';
import { useGlobalContext } from '@store/global-context/use-global-context';

import styles from './LogIn.view.module.scss';

export const LogInView = () => {
  const {
    user: { isAuthenticated },
  } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [commonError, setCommonError] = useState<string>();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await authService.logIn(data.email, data.password);

      navigate(routes.dashboard);
    } catch (e: any) {
      setIsLoading(false);

      if (['auth/user-not-found', 'auth/wrong-password'].includes(e.code)) {
        setCommonError('Email or password incorrect');
      } else {
        throw e;
      }
    }
  }, []);

  if (isAuthenticated) return <Navigate to={routes.dashboard} />;

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Container className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <Heading level="h2" className={styles.logo}>
            Heep
          </Heading>
        </div>

        <div>
          <Heading level="h1" className={styles.title}>
            Log in
          </Heading>
          <EmailInput name="email" isRequired />
          <PasswordInput name="password" isRequired />
          <ErrorMessage>{commonError}</ErrorMessage>

          <Link to={routes.forgotPassword} className={styles.link}>
            Forgot password
          </Link>
        </div>

        <div className={styles.actionsWrapper}>
          <Button type="submit" {...{ isLoading }}>
            Submit
          </Button>
          <Link to={routes.signUp} className={styles.link}>
            Don't have an account? <strong>Sign up!</strong>
          </Link>
        </div>
      </Container>
    </Form>
  );
};
